import React, {useState} from "react"
import { Button } from 'antd';
import Filters from "./Filters";
import "./Toolbar.scss";

const Toolbar = ({action, filters}) => {
  const [reset, setReset] = useState(false);

  const handleReset = () => {
    setReset(prevReset => !prevReset);
  };

  return (
    <form className="ant-form ant-form-vertical" action={action} method="get">
      <div className="Toolbar">
        <Filters filters={filters} reset={reset}/>

        <div className="Toolbar Actions">
          <Button type="primary" htmlType="submit">Filtrar</Button>
          <Button type="default" onClick={handleReset}>Reset</Button>
        </div>
      </div>
    </form>
  );
}

export default Toolbar;
